.policy-container {
    max-width: 1000px;
    margin: 40px auto;
    padding: 30px;
    background: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    transition: transform 0.3s;
}

.policy-container:hover {
    transform: scale(1.02);
}

.policy-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
    border-bottom: 2px solid #2980b9;
    padding-bottom: 10px;
}

.policy-container h2 {
    font-size: 1.8em;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #2980b9;
}

.policy-container p {
    line-height: 1.7;
    font-size: 1.1em;
    margin-bottom: 15px;
    color: #555;
}

.policy-container ul {
    margin-left: 20px;
}

.policy-container a {
    color: #2980b9;
    text-decoration: none;
    transition: color 0.3s;
}

.policy-container a:hover {
    color: #1a669c;
}

.policy-container form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60%;
}

.policy-container label {
    margin: 10px 0 5px;
    font-weight: bold;
    width: 100px;
}

.policy-container .contact_us_form {
    display: flex;
}

.policy-container input, .policy-container textarea {
    padding: 12px;
    width: 100%;
    margin-bottom: 15px !important;
    border: 1px solid #ccc !important;
    border-radius: 6px !important;
    transition: border 0.3s;
}

.policy-container input:focus, .policy-container textarea:focus {
    border: 1px solid #2980b9;
    outline: none;
}

.policy-container button {
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    background-color: #2980b9;
    color: white;
    font-size: 1.1em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.policy-container button:hover {
    background-color: #1a669c;
    transform: translateY(-2px);
}

.contact-us{
    display: flex;
}
  
.contact-info{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 20px 10px;
}

.contact-info .call-us,
.contact-info .contact-location{
    width: 50%;
}

.contact-info .call-us .sub-text,
.contact-info .contact-location .sub-text{
    font-size: 1rem;
    line-height: 1;
}

.contact-info .call-us .head-text,
.contact-info .contact-location .head-text{
    font-size: 1.4rem;
    color: #000;
    line-height: 1;
}

.contact-info .call-us img,
.contact-info .contact-location img{
    width: 30px;
    margin-bottom: 10px;
    margin-right: 10px;
}

@media (max-width: 600px) {
    .policy-container {
        padding: 20px;
        width: 100%;
    }

    .policy-container h1 {
        font-size: 2em;
    }

    .policy-container h2 {
        font-size: 1.5em;
    }

    .policy-container p {
        font-size: 1em;
    }

    .contact-us{
        display: flex;
        flex-direction: column-reverse;
    }

    .policy-container form{
        width: 100%;
    }

    .contact-info{
        width: 100%;
        margin-top: 20px;
    }
}
