body {
  margin: 0;
  font-family: 'varela round', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  text-decoration: unset !important;
}
body, body * {
  font-family: 'varela round', sans-serif !important;
}

#root {
  background: #fff;
}

a {
  text-decoration: none !important;
}

.search_icon a img{
  width: 2.5rem;
}

.mobile_btn{
  display: none !important;
}

.login-content input[type='checkbox']{
  margin-right: 0.3rem;
}

.auth-form input[type='checkbox']{
  margin-right: 0.5rem;
}

.login-content .password,
.auth-form .password{
  padding: .375rem .75rem !important;
}

.wallfleur-logo{
  height: 100px;
  width: 200px;
}

.breadcrums{
  color: #989898;
  font-size: 14px;
}

.breadcrums a{
  color: #989898 !important ;
}

.breadcrums a:hover{
  text-decoration: underline !important;
}

.thank-you p {
  padding: 10px 60px;
  font-size: 25px;
}

.news-letter{
  display: flex;
  justify-content: start;
  gap: 20px;
}

.news-letter .mail{
  width: 50%;
}

.news-letter button{
  width: 100px;
  height: 40px;
  color: #695b57 ;
}

.iti{
  display: block !important;
}

input {
  border: none !important;
  outline: none;
  border-bottom: 1px solid !important;
  border-radius: 0px !important;
}

select {
  border: none !important;
  outline: none;
  border-bottom: 1px solid !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

.d-flex{
  justify-content: start !important;
  flex-wrap: wrap;
  gap: 40px;
}

.form-control:focus{
  box-shadow: none !important;
  border-bottom: 4px solid !important;
}

.form-label{
  font-weight: 600;
}

.wallfleur_row{
  padding-left: 15rem;
  padding-right: 15rem;
}

.catloader{
  background: white;
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.catloader img{
  width: 50%;
}

.notfound{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.paypal-confirmation{
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.paypal-confirmation h1{
  color: #007bff;
}

.notfound p{
  font-size: 100px;
  font-weight: 500;
}

.country-option{
  cursor: pointer;
}

.cart-count{
  background: #007bff;
  color: #fff;
  border-radius: 10px;
  padding: 0px 5px;
  position: relative;
  top: -10px;
  right: 5px;
  font-size: 15px;
}

.login-heading{
  text-align: center;
}

.prod_quantity{
  margin: 10px 0;
  display: flex;
  gap: 20px;
}

.quantity-controls {
  display: flex;
  border: 1px solid;
  width: 120px;
  height: 40px;
  justify-content: space-around;
}

.quantity-controls button {
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: #000;
}

.quantity-controls span {
  margin: 0 10px;
}

.wall-banner{
  position: relative;
}

.wall-banner svg{
  position: absolute;
  display:block;
  width:100%;
  height:auto;
  fill:#ffff;
  bottom: -40px;
}

.slide-card{
  border: none;
  display: inline-block;
  width: 100%;
  height: 650px;
}

.slide-card .card-img-top {
  display: block;
  width: 100%;
  height: 600px;
}

.bag-prod-container .card-top {
  margin-top: 10px;
  height: 8rem;
  width: 11rem;
  border-radius: 15px;
}

.bag-heaqding{
  margin-top: 10px;
  padding-left: 9%;
}

.bag-empty{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.bag-prod-card .prod-card-body{
  display: block;
  margin-top: 20px;
  width: 20rem;
  text-align: left;
}

.product-name{
  font-size: 1.7rem;
  line-height: 1.3;
}

.prod-name-share{
  display: flex;
  gap:1rem;
}

.share-social{
  position: relative;
}

.share-social .share-img{
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}

.share-social .shareable-icons{
  list-style: none;
  display: flex;
  gap: 10px;
  border: 1px solid #ddd;
  padding: 5px;
  margin: 0;
  z-index: 9999;
  position: absolute;
  cursor: pointer;
  background: #fff;
}

.share-social .shareable-icons img{
  width: 1.5rem;
  height: 1.5rem;
}

.share-social .shareable-icons p{
  margin: 0;
}

.product-price{
  font-size: 1.3rem;
}

.bag-container{
  display: grid;
  grid-template-columns: 1fr 400px;
  gap: 40px;
  padding: 20px;
}

.bag-left{
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.bag-right{
  width: 20rem;
  height: 300px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 20px;
}

.bag-prod-container{
  height: auto;
  gap: 15px;
}

.bag-summary{
  margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.bag-summary-item{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
}

.bag-prod-container .bag-prod-card{
  margin-top: 10px;
  margin-bottom: 10px;
  height: 170px;
  width: 100%;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.bag-prod-card{
  text-align: center;
}

.removefromcard {
  border: none;
  background-color: #fff;
}

.checkout-subtotal{
  margin-bottom: 0;
  font-size: 1.5rem;
}

.checkout-btn-container {
  text-align: center;
  margin: 20px 0;
}

/* Checkout button styling */
.checkout-btn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

.bag a img{
  width: 1.9rem;
}


.search-mob {
  width: 1.9rem;
}

.profile {
  text-align: center;
  width: 30px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 20px;
}

.search_icon{
  font-size: 20px;
  margin-right: 10px;
}

.profile-menu {
  display: block;
  font-size: 18px;
  z-index: 99;
  position: absolute;
  padding: 10px 0px;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.profile-menu .menu-list {
  display: block;
  width: 100%;
  color: #222;
  cursor: pointer;
  padding: 5px 20px;
}

.profile-menu .menu-list:hover {
  color: #222;
  cursor: pointer;
  background-color: #dec3bb;
}

.bag{
  margin-top: 3px;
  margin-right: 15px;

}

.bag a{
  font-size: 20px;
  color: #222;
  text-decoration: none !important;
}

.product-card {
  display: flex;
  flex-wrap: nowrap;
  justify-content:start !important;
  gap: 2rem;
  height: 22.5rem;
}

.product-card .common-card {
  text-decoration: none;
}

.product-card .common-card .card {
  border: none;
  height: 260px;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.category-product-list{
  padding-bottom: 50px;
}

.category-product-list .product-card{
  display: flex;
  flex-wrap: wrap;
  height: auto;
  gap: 1rem;
}


.category-card-body{
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}

.product-card .common-card .card .card-img-top {
  width: 200px;
  height: 200px;
}

.prod-card {
  background-color: white;
  height: 22rem;
  width: 300px;
  border: none;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.preorder-badge{
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
}

.preorder-badge img{
  width: 15rem;
  height: 3rem;
  margin-left: -2rem;
  object-fit: cover;
}

.prod-name {
  font-size: 1.1rem;
  color: #222;
  font-weight: 500;
  margin-bottom: 5px;
}

.prod-price {
  font-size: 1.2rem;
  font-weight: 500;
  color: #222;
  margin-bottom: 0px;
}


.prod-card .prod-img {
  text-align: center;
  overflow: hidden; 
}

.sold_out {
  position: relative; 
}

.sold_out span {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  color: #000;
  font-size: 25px; 
  background-color: rgba(255,255,255,.7);
  padding:10px;
  text-align: center;
  width: 100%;
  font-weight: 600;
}

.sold_out_btn{
  display: none !important;
}

.prod-card .card-top {
  height: 260px;
  width: 100%;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.prod-card .card-top img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease; 
}

.prod-card .prod-card-body {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prod-card-body .add-to-bag{
  text-align: right;
}

.prod-card-body .add-to-bag img{
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.prod-card-body .add-to-bag img:hover {
  content: url('https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/add-to-cart-dark.png');

}

.prod-note{
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1rem;
  background-color: #f2e8e6;
  color: #c03f48;
}

.prod-note p{
  margin: 0;
  line-height: 1.4;
}

.prod-card-body {
  text-align: left;
}

.prod-card-body .prod-btn .addtocart {
  border-radius: 0px;
  background-color: white;
  box-shadow: none;
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  color: #000;
  border: 1px solid;
}
.prod-card-body .prod-btn .addtocart:hover{
  background-color: #000;
  color: #fff;
}

.common-card {
  border: none;
  width: 90%;
  display: inline-block;
}

.common-card .card-img-top {
  width: 100%;
  height: 210px;
  border-radius: 15px 15px 0 0;
}

.common-card .card-text {
  color: #222;
  font-size: 16px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.common-card .card-price {
  color: #222;
  font-size: 16px;
}

.slick-prev:before,
.slick-next:before {
  background-color: #d4c4ec !important; /* Set the color for .slick-prev:before */
  border-radius: 10px;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  display: none;
}

form button {
  background-color: #d4c4ec !important;
  border-color: #d4c4ec !important;
}

.submit-btn:hover {
  background-color: #9255ee !important;
}
.productslide .slick-prev:before,
.productslide .slick-next:before {
  display: none !important;
}

.loginmodal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
  width: 800px;
}

.loginmodal .modal-content{
  overflow: hidden;
}

.loginmodal .modal-content .close-button{
  left: 45%;
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(34, 34, 34);
  border: none;
  font-size: 20px;
}

.login-content {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.login-content .Login,
.login-content .register{
  width: 60%;
}

.login-content .sign-in-up span{
  cursor: pointer;
}

.countrymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
  width: 40%;
}

.modal-content {
  text-align: center;
  align-items: center;
}

.modal-content p {
  padding: 10px;
  font-size: 18px;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.blur {
  backdrop-filter: blur(8px);
}

.backdrop.blur {
  display: block;
}
.countrybtn {
  width: 70%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.countrybtn button {
  padding: 6px 20px;
  border-radius: 5px;
  background-color: #d4c4ec;
  border: 1px solid #d4c4ec;
}
.countrybtn button:hover {
  background-color: #9255ee !important;
}

/* Styling for the select box */
.countrybtn select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  font-size: 16px;
  width: 60%;
  box-sizing: border-box;
  cursor: pointer;
}

/* Styling for the options within the select box */
.countrybtn select option {
  background-color: #fff;
  color: #333;
}

.firstfold {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  padding: 20px;
}

.product-details {
  margin-top: 20px;
  padding: 20px;
  line-height: 2.1;
}

.product-details .description {
  line-height: 1.5;
  font-size: 1rem;
}

.product-details .description p,
  .product-details .description li{
  margin-bottom: 0;
}


.product-badges{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 30px 150px;
}

.product-badges .nav-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.product-badges img{
  width: 10rem;
}

.product-badges span{
  font-size: 0.7rem;
  color: #7c7a7a;
}


.prod_button {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.firstfold .description .read-more{
  cursor: pointer;
  text-decoration: underline;
}

.prod_button .addtocart {
  width: 215px;
  height: 50px;
  border-radius: 0px !important;
  background: white;
  padding: 8px 30px;
  color: black;
  cursor: pointer;
  box-shadow: none;
  border: 1px solid;
}

.prod_button .addtocart:hover{
  background-color: #000;
  color: #fff;
}

.prod_button .wishlist {
  border: 1px solid #e99797;
  border-radius: 20px;
  background-color: #e99797;
  padding: 3px 30px;
  cursor: pointer;
}

.prod_button .wishlist:hover {
  background-color: #ec5a5a;
}

.prod_slider {
  width: 500px;
}

.prod-content{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prod_gallery {
  display: flex;
}
.prod-container {
  display: flex; /* Align thumbnails and preview side by side */
  width: 100%; /* Full width of the container */
  max-width: 800px; /* Maximum width to control layout */
}

.thumbnails {
  display: flex; 
  flex-direction: column; /* Stack thumbnails vertically */
  margin-right: 20px; /* Space between thumbnails and preview */
  flex-shrink: 0; /* Prevent shrinking */
  width: 80px; /* Adjust as needed */
}

.thumbnail-img {
  width: 60px; /* Thumbnail width */
  height: 60px; /* Thumbnail height */
  margin-bottom: 10px; /* Space between thumbnails */
  cursor: pointer; /* Indicate clickable items */
  object-fit: cover; /* Maintain aspect ratio and fill the container */
}

.preview {
  flex-grow: 1;
  display: flex; 
  position: relative;
}

.preview-img {
  width: 100%; 
  height: 500px; 
}

/* Modal styles */
.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.image-modal-overlay .modal-content {
  display: flow;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  height: 100%;
  overflow: auto;
  position: relative;
}
.image-modal-overlay .modal-content img {
  width: 95%;
  height: 100%;
  /* object-fit: cover; */
}

.image-modal-overlay .modal-content .prev-btn,
.image-modal-overlay .modal-content .next-btn {
  border: none;
  border-radius: 50%;
  padding: 1px 8px;
  position: absolute;
  top: 50%;
  width: 39px !important;
  height: 39px !important;
}

.image-modal-overlay .modal-content .prev-btn {
  background-image: url(https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/arrow_left.svg) !important;
  background-repeat: no-repeat !important;
}


.image-modal-overlay .modal-content .next-btn {
  right: 60px;
  background-image: url(https://wallfleur-images.s3.ap-south-1.amazonaws.com/common/arrow_right.svg) !important;
  background-repeat: no-repeat !important;
}

.image-modal-overlay .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/*footer */

.footerbgimg{
  padding-left: 15rem;
  padding-right: 15rem;
  background-color: #F5F5F5;
}

.footerbgimg .d-flex{
  gap: 10px;
}

.socials{
  display: flex;
  gap: 1rem;
}

.socials img{
  width: 50px;
  height: 50px;
}


/* AuthTransition.css */

.auth-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form {
  width: 60%;
  height: auto;
}

.auth-form .terms-condition{
  font-size: 12px;
  color: grey;
  margin: 1rem 0;
}

.auth-form .sign-in-up span{
  cursor: pointer;
}

.login-content .terms-condition{
  font-size: 12px;
  color: grey;
  margin: 0.1rem 0;
}

.auth-form .login_heading {
  display: flex;
  justify-content: center;
}

.auth-form .verify_otp {
  border-radius: 15px;
  width: 80%;
}

.auth-form .verify_btn{
  text-align: center;
}

.auth-form .shadow {
  padding: 10px;
}

.otp-input-parent{
  display: flex;
  justify-content: center;
  gap: 25px;
}

.otp-input {
  height: 60px;
  width: 60px;
  text-align: center;
  padding: 18px !important;
  font-size: 25px !important;
  border: 1px solid !important;
  outline: rgb(248, 255, 240) !important;
  margin: 20px 0px;
  border-radius: 15px !important;
}


/* AuthTransition.css */

.addtocartbtn {
  --transition: 0.25s;
  --spark: 3s;
  padding: 6px 18px;
  display: grid;
  border-radius: 9999px;
  position: relative;
  overflow: hidden;
  transition: box-shadow var(--transition), background var(--transition), transform var(--transition);
}

.addtocartbtn:hover .backdrop {
  background: rgb(20 20 20);
}

.addtocartbtn:hover {
  transform: scale(1.05);
}

.spark {
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  rotate: 0deg;
  overflow: hidden;
  mask: linear-gradient(white, transparent 50%);
  animation: flip calc(var(--spark) * 2) infinite steps(2, end);
}

@keyframes flip {
  to {
    rotate: 360deg;
  }
}

.spark:before {
  content: "";
  position: absolute;
  width: 200%;
  aspect-ratio: 1;
  inset: 0 auto auto 50%;
  z-index: -1;
  translate: -50% -15%;
  rotate: 0;
  transform: rotate(-90deg);
  opacity: 1;
  background: conic-gradient(from 0deg, transparent 0 340deg, white 360deg);
  transition: opacity var(--transition);
  animation: rotate var(--spark) linear infinite both;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* Cover entire viewport width */
  height: 100%; /* Cover entire viewport height */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.qa-section{
padding: 30px 0px;
}

.qa-section .accordion-item{
  padding: 15px;
  border-bottom: 1px solid;
}

.qa-section .accordion-item .accordion-header button{
  font-weight: 600;
  font-size: 20px;
}

.qa-section .accordion-item .accordion-body{
  padding:10px 0px;
  font-size: 18px;
  line-height: 1.4;
}

.qa-section .qa_seemore{
  background: #bcf8ed;
  padding: 15px;
  border-radius: 40px;
  text-decoration: none;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
  cursor: pointer;
}

.qa-section .qa_seemore:hover{
  font-weight: 600;
    color: white;
    background: #333938;
}

.home-preorder{
  margin-top: 2rem;
  margin-bottom:3rem;
}

.home-preorder .heading-text{
margin: 0;
}

.home-preorder  .category-heading{
  text-align: center;
  margin: 20px 0;
}
.home-preorder  .category-heading p{
  font-size: 1.5rem;
  margin-top: 1rem;
}

.home-category{
  margin: 3rem 0;
}

.home-category .category-heading{
  text-align: center;
  margin: 20px 0;
}
.home-category .category-heading p{
  font-size: 1.5rem;
}

.featured-products{
  background-color: #eee6e0;
  padding: 30px 15rem;
  text-align: center;
}

.featured-products h2{
  text-align: center;
}

.featured-products .product-card{
  height: 22rem;
}

.featured-products .prod-card-body{
  background-color: #f9f6f7;
}

.featured-products .prod-name {
  color: #70565E !important;
}

.featured-products .prod-price{
  color: #70565E !important;
  font-size: 1rem;
}

/* .featured-products .prod-card{
  background-color: transparent !important;
} */

.bento-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.bento-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  flex: 1 1 calc(33.333% - 10px); /* Regular items take up one-third */
  height: 25rem; /* Adjust height as needed */
  cursor: pointer;
  position: relative;
}

.bento-item.large {
  flex: 2 1 calc(66.666% - 10px); /* Large item spans two columns */
  height: 25rem; /* Adjust as needed */
}

.bento-item.view-all {
  flex: 2 1 calc(66.666% - 10px);
  height: 3.4rem;
  font-size: 1.1rem;
  color: #70565E;
  background-color: #f5eff1;
}

.bento-container .overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bento-name {
  font-size: 1.7rem;
  font-weight: bold;
  color: #fff; /* White text for contrast */
  text-align: center;
}

.bento-item:hover {
  filter: brightness(1.2); /* Brightens the image on hover */
}

.bento-item.view-all:hover {
  background-color: #daaaba;
  filter: unset;
}

.bento-item:hover .overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay */
}

.faq-section{
  display: flex;
  align-items: center;
  gap: 4rem;
  margin:60px 0px;
}

.faq-section .heading-text{
  width: 40%;
  text-align: center;
}

.heading-text{
  margin: 1rem 0;
  font-size: 2.5rem;
}

.about-content {
  display: flex;
  gap: 5rem;
  align-items: center;
  padding: 2rem;
}

.about-container {
  background-color: #F6F0FB;
  color: #6D5D84;
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
}

.about-image {
  width: 70%;
  height: 20rem;
  border-radius: 10px;
}

@keyframes rotate {
  to {
    transform: rotate(90deg);
  }
}

@media (max-width: 1080px) {

  .home-preorder{
    margin-top: 0;
  }

  .wall-banner svg{
    bottom: -1px;
  }

  /* .featured-products .product-card{
    display: flex;
  } */

  .profile-menu{
    right: 2rem;
  }

  .product-badges img{
    width: 8rem;
  }

  .currency-selector.product-page {
    bottom: 5rem; 
  }

  .wallfleur_row {
    padding-left: 75px;
    padding-right: 75px;
  }

  .prod-card-body .add-to-bag img{
    width: 1.6rem;
    height: 1.5rem;
  }

  .bag-container {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .bag-prod-container .card-top{
    width: 7rem;
  }

  .preorder-badge img{
    width: 12rem;
  }

  .catloader img{
    width: 50%;
  }

  .offcanvas-body {
    text-align: center;
    line-height: 2.5;
  }

  .flex-column a {
    font-size: 25px;
    font-weight: 500;
  }

  .offcanvas-body a, .offcanvas-body p {
    color: #000;
  }

  .product-badges{
    padding: 30px 75px;
  }

  .featured-products{
    padding-left: 75px;
    padding-right: 75px;
  }

  .category-product-list .product-card .prod-card{
    width: 100%;
  }

  .bento-item:hover {
    filter: unset;
  }
  
  .bento-item:hover .overlay {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .footerbgimg{
    padding-left: 75px;
    padding-right: 75px;
  }

  .auth-form {
    width: 100% !important;
    height: auto;
  }

  .prod_button {
    display: block;
    font-size: 18px;
    width: 100%;
  }
  .prod_button .addtocart {
    border-radius: 30px;
    padding: 3px;
    width: 100%;
    margin-bottom: 10px;
  }

  .prod_button .wishlist {
    border: 1px solid #ec5a5a;
    border-radius: 30px;
    background-color: #ec5a5a;
    padding: 3px;
    width: 100%;
  }
  .prod_slider {
    width: 360px;
  }
  .firstfold {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .d-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .prod-container {
    flex-direction: column-reverse;
  }
  .thumbnails {
    margin-top: 5px;
    margin-right: 0;
    flex-direction: row;
    overflow-x: auto;
    width: auto;
  }

  .category-product-list .product-card {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

  .product-card{
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    height: auto !important;
    gap: 2rem;
  }

  .about-content {
    flex-direction: column;
    gap: 2rem;
  }

  .about-image {
    width: 100%;
    height: 25rem;
}

  .prod-card .prod-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 20px;
    gap: 2.5rem;
  }

  .category-pro{
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 0 !important;
  }

  .prod-card{
    height: auto;
    width: 100%;
  }

  .skeleton-image {
    width: 90%;
    height: 400px !important;
    margin-bottom: 20px;
  }

  .paypal-confirmation{
    padding: 0 30px;
  }

  .search-mob{
    width: 2.5rem;
  }

  .bag a img{
    margin-bottom: -1rem;
  }

}

@media (max-width: 767px) {

  .prod-name-share{
    gap: 1rem;
  }

  .share-social .shareable-icons{
    right: 0;
  }

  .mobile_btn.sold_out_btn {
    display: none !important;
  }

  .search-mob{
    width: 1.9rem;
  }

  .bag a img{
    width: 1.5rem;
    margin-bottom: -0.4rem;
  }

  .category-pro{
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
    gap: 0 !important;
  }

  .wall-banner svg{
    bottom: -1px;
  }

  .home-preorder{
    margin-top: 0;
  }

  .home-preorder  .category-heading p{
    font-size: 0.8rem;
  }

  .currency-selector.product-page {
    bottom: 5rem; 
  }

  .product-badges img{
    width: 7rem;
  }

  .navbar-toggler{
    padding: 0 !important;
  }

  .socials img{
    width: 40px;
    height: 40px;
  }

  .prod_quantity{
    flex-direction: column;
    gap: 0;
  }

  .mobile_btn{
    display: block !important;
    position: fixed;
    z-index: 999;
    bottom: 0;
    margin: 0;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff !important;
  }

  .mobile_btn .addtocart{
    border: none !important;
    border-radius: 30px !important;
    margin: 0 !important;
    width: 100% !important;
    padding: 0.6rem !important;
    font-weight: 600 !important;
    color: #695b57 !important;
    background-color: #d7caeb !important;
  }

  .desktop_btn{
    display: none !important;
  }

  .wallfleur-logo{
    height: 45px;
    width: 110px;
    margin-top: -1rem;
    position: relative;
    left: 1.4rem;
  }

  .catloader img{
    width: 75%;
  }

  .bento-item:hover {
    filter: unset;
  }
  
  .bento-item:hover .overlay {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
  }
  

  .category-product-list .product-card .prod-card{
    width: 100%;
  }

  .auth-form .terms-condition{
    font-size: 10px;
    color: grey;
    margin: 1rem 0;
  }
  
  .login-content .terms-condition{
    font-size: 10px;
    color: grey;
    margin: 0.1rem 0;
  }

  .login-content .Login, .login-content .register{
    width: 90%;
  }

  .login-content .sign-in-up{
    font-size: 12px;
  }

  .login-content .sign-in-up span{
    font-size: 12px;
  }

  .bag-prod-container .card-top{
    width: 120px;
    height: 120px;
  }
  .prod-card{
    height: auto;
    width: 100%;
    border-radius: 15px 15px 0 0;
  }

  .slide-card{
    height: 275px;
  }

  .slide-card .card-img-top {
    width: 100%;
    height: 255px;
  }
  
  .auth-form {
    width: 100% !important;
    height: auto;
  }

  .loginmodal{
    width: 23rem !important;
  }

  .wallfleur_row{
    padding-left: 30px; 
    padding-right: 30px;
    margin-left: 0px  !important;
    margin-right: 0px !important;
  }

  .product-badges{
    padding: 0;
  }

  .featured-products{
    padding-left: 30px; 
    padding-right: 30px;
    margin-left: 0px  !important;
    margin-right: 0px !important;
  }

  .faq-section{
    flex-direction: column-reverse;
    gap: 0;
    margin: 30px 0;
  }

  .faq-section .heading-text {
    width: 100%;
    text-align: center;
}

  .footerbgimg{
    padding-left: 30px; 
    padding-right: 30px;
    margin-left: 0px  !important;
    margin-right: 0px !important;
  }

  .offcanvas-body{
    text-align: center;
    line-height: 2.5;
  }

  .offcanvas-body a,
  .offcanvas-body p{
    color: #000;
  }

  .menu-title {
    font-size: 30px;
    font-weight: 600;
  }

  .flex-column a {
    font-size: 25px;
    font-weight: 500;
  }

  .footerbgimg .flex-column a {
    font-size: 14px;
    font-weight: 500;
  }


  .flex-column .logout {
    font-size: 25px;
    font-weight: 500;
  }
  /* Mobile view: Show 2 cards in a column */
  .d-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .d-grid .prod-card {
    width: 150px;
    height: auto;
  }

  .product-card .common-card .card .card-img-top {
  width: 150px;
  height: 150px;
}

  .product-card {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 0.5rem;
    height: auto !important;
  }

  .product-card .common-card .card {
    border: none;
    height: auto;
  }

  .prod_gallery{
    justify-content: center;
  }

  .prod-name {
    font-size: 16px;
    margin-bottom: 4px;
    color: #222;
    font-weight: 500;
    text-decoration: none !important;
  }

  .prod-price {
    font-size: 1rem;
    color: #222;
    font-weight: 500;
    text-decoration: none;
  }

  .prod-card .prod-img{
    text-align: unset !important;
  }

  .prod-card .card-top {
    width: 100%;
    height: 170px;
    border-radius: 15px 15px 0 0;
  }

  .prod-card .prod-card-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 8px;
    gap: 0rem;
  }
  .prod-card-body .prod-btn .addtocart {
    margin-top: 0px;
    padding: 8px;
    width: 85%;
  }
  .thank-you p {
    padding: 10px;
    font-size: 15px;
  }

  .countrymodal {
    width: 90%;
  }

  .countrybtn {
    width: 100%;
  }

  .countrybtn select {
    width: 70%;
  }

  .firstfold {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px; 
    padding: 0px !important;
  }

  .prod-container {
    width: 100% !important;
  }

  .preview-img {
    width: 100%;
    height: 320px;
  }

  .preview span{
    width: 100%;
  }

  .product-details {
    margin-top: 20px;
    padding: 0;
  }

  .prod_button {
    display: block;
    font-size: 18px;
    width: 100%;
  }
  .prod_button .addtocart {
    border-radius: 30px;
    padding: 3px;
    width: 90%;
    margin-bottom: 10px;
  }

  .prod_button .wishlist {
    border: 1px solid #ec5a5a;
    border-radius: 30px;
    background-color: #ec5a5a;
    padding: 3px;
    width: 100%;
  }
  .prod_slider {
    width: 360px;
  }

  .prod-container .slick-prev:before,
  .prod-container .slick-next:before {
    display: none;
  }

  .productslide .slick-prev:before,
  .productslide .slick-next:before {
    display: none !important;
  }
  .image-modal-overlay .modal-content img {
    width: 100%;
    height: 100%;
  }
  .image-modal-overlay .modal-content {
    width: 100%;
    height: 60%;
  }
  .image-modal-overlay .modal-content .next-btn {
    right: 20px;
  }
  .image-modal-overlay .close-btn {
    top: 0px;
    right: 2px;
  }

  .d-grid .cat-card {
    width: 150px;
    height: 230px;
  }

  .cat-card .cat-img-cont .cat-img {
    width: 160px;
    height: 160px;
  }

  .cat-name {
    font-size: 18px;
    margin-bottom: 4px;
    color: #222;
    font-weight: 500;
    text-decoration: none !important;
  }

  .cat-price {
    font-size: 16px;
    color: #222;
    font-weight: 500;
    text-decoration: none;
  }

  .bag{
    margin-right: 0 !important;
  }

  .removefromcard {
    margin-top: 0px;
    border-radius: 17px;
    padding: 1rem;
  }

  .suggestions-list{
    width: 100% !important;
    top: 90% !important;
  }

  .no-suggest{
    width: 100% !important;
    top:90% !important;
  }

  .bag-container {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .bag-right {
    order: 2;
    position: static;
    height: 20rem;
  }

  .bag-left {
    order: 1; 
    width: 20rem;
  }

  .bag-prod-container{
    padding: 0px;
    max-height: 450px;
  }

  .bag-prod-container .bag-prod-card{
    position: relative;
    flex-wrap: nowrap;
  }

  .bag-prod-container .bag-prod-card .prod-btn{
    position: absolute;
    top: 0;
    right: 0;
  }

  .bag-heaqding{
    padding: 0px;
  }

  .bag-prod-card .prod-card-body{
    margin-top: 0px;
    padding: 10px;
    width: 10rem;
   }
   .bag-prod-container .card-top{
    padding: 10px;
   }
   .prod-container {
    flex-direction: column-reverse;
  }

  .thumbnails {
    margin-top: 5px;
    margin-right: 0;
    flex-direction: row; 
    overflow-x: auto;
    width: 300px;
  }

  .thumbnail-img {
    margin-right: 10px; 
  }

  .sold_out span {
    font-size: 14px;
    bottom: -25px;
  }
  .otp-input-parent{
    display: flex;
    justify-content: center;
    gap: 10px !important;
  }
  
  .otp-input {
    font-size: 18px !important;
    height: 50px !important;
    width: 50px !important;
  }

  .news-letter .mail{
    width: 60%;
  }

  .news-letter button{
    width: 100px;
    height: 40px;
  }

  .common-card .card-img-top{
    width: 100%;
    height: 190px;
    object-fit: cover;
  }

  .qa-section .accordion-item .accordion-header button{
    font-size: 16px;
  }

  .qa-section .accordion-item .accordion-body{
    font-size: 14px;
  }

  .about-content{
    flex-direction: column;
    gap: 2rem;
  }
  
  .about-image{
      width: 100% !important;
      height: 20rem !important;
  }

  .skeleton-image {
    width: 280px !important;
    height: 280px !important;
  }

  .paypal-confirmation{
    padding: 0 30px;
  }

  .bento-item {
    flex: 1 1 100%; /* Large item spans full width on tablet */
    height: 25rem; /* Adjust height for large item */
  }

  .bento-name {
    font-size: 2.7rem; /* Smaller font size on tablets */
  }

  .bento-container .overlay {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .bento-item.view-all {
    height: 3rem;
  }

  .heading-text{
    font-size: 1.5rem;
    font-weight: 600;
  }

  .home-category .category-heading p{
    font-size: 1rem;
  } 

  .category-product-list .product-card{
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

  .preorder-badge img{
    width: 10rem;
  }
}

@media (min-width: 340px) and (max-width: 390px){ 

  .product-badges img{
    width: 5rem;
  }
  .category-product-list .product-card .prod-card {
      width: 10rem;
  }

  .bento-item:hover {
    filter: unset;
  }

  .preorder-badge img{
    width: 8rem;
    margin-left: -1rem;
  }
  
  .bento-item:hover .overlay {
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .thumbnails {
    width: 280px;
  }
  
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -150px;
  height: 100%;
  width: 150px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}

/* Skeleton for specific elements */
.skeleton-desc {
  height: 100px;
  width: 100%;
  margin-bottom: 10px;
}
.skeleton-text {
  height: 30px;
  width: 260px;
  margin-bottom: 20px;
}
.skeleton-heading {
  height: 50px;
  width: 200px;
  margin-bottom: 20px;
}

.skeleton-button {
  width: 250px;
  height: 60px;
  margin-bottom: 20px;
}

.skeleton-image {
  width: 90%;
  height: 90%;
  margin-bottom: 20px;
}

.copyright{
  font-size: 0.8rem;
}

/* currency selector */

.currency-selector {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: white;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 5px;
  z-index: 998; 
  cursor: pointer;
}

.selected-country {
  padding: 5px 10px;
  border-radius: 5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 5px;
  z-index: 100;
  width: 100%;
}

.dropdown-option {
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.dropdown-option img{
  width: 2rem;
}

.dropdown-option p{
  margin-bottom: 0;
}

.section {
  opacity: 0;
  transform: translateY(50px); /* Start 50px below */
  transition: all 0.6s ease-out;
}

/* When the section becomes visible */
.section.visible {
  opacity: 1;
  transform: translateY(0);
}