.checkout-container {
    display: grid;
    grid-template-columns: 1fr 400px; /* Left section takes remaining space, right section is fixed at 400px */
    gap: 40px;
    padding: 20px;
}

.checkout-left {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.checkout-left .payment{
    padding: 30px;
}

.checkout-left .payment-options{
    padding: 30px 0px;
}

.checkout-left .payment-options .or-text,
.checkout-left .paypal-container .or-text{
    display: flex;
    align-items: center;
    width: 50%; 
    padding: 30px;
}

.checkout-left .payment-options p,
.checkout-left .paypal-container p{
    padding: 0px 20px;
    margin-bottom: 0px;
}

.checkout-left .payment-options .line,
.checkout-left .paypal-container .line{
    flex: 1;
    height: 3px; 
    background-color: #ccc; 
    margin: 0; 
}

.checkout-right {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 20px;
}

.cart-items {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;

}

.checkout-card {
    position: relative;
    padding: 10px;
    border-bottom: 1px solid;
}

.checkout-card .checkout-prod-img{
    width: 80px;
    height: 80px;
}

.checkout-content {
    width: 50%;
    line-height: 1.5;
}

.remove-prod {
    text-decoration: underline;
    margin-bottom: 0px;
    padding: 0px;
    cursor: pointer;
}

.checkout-name {
    font-weight: bold;
    margin-bottom: 0px;
    padding: 0px;
}

.checkout-price {
    margin-bottom: 0px;
    padding: 0px;
}

.checkout-container h3 {
    margin-bottom: 20px;
}

.checkout-container .form-group {
    margin-bottom: 20px;
}

.checkout-container .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.checkout-container .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.checkout-container button {
    display: block;
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.checkout-card-body {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
}

.remove-btn:hover {
    color: #ff7875;
}

.checkout-summary {
    line-height: 1.8;
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ddd;
}

.checkout-summary-item {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
}

.checkout-summary-item.total {
    font-weight: bold;
    font-size: 18px;
    background-color: #0056b3;
    border-radius: 10px;
    color: #fff;
    padding: 8px;
    margin-top: 10px;
}

.payment-container {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.btn-pay {
    display: block;
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}

.btn-pay:hover {
    background: #0056b3;
}

/* Shipping Details Styles */

.select-disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
    color: grey;
  }

.shipping-details .form-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.shipping-details input:focus {
    border-bottom: 4px solid !important;
  }

.shipping-details select {
    background-color: #f8f8f8;
}

.shipping-details h3 {
    margin-bottom: 20px;
}

.shipping-details .form-group {
    margin-bottom: 20px;
    flex: 0 0 48%;
    box-sizing: border-box;
}

.shipping-details .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.shipping-details .form-group input,
.shipping-details .form-group select {
    width: 100%;
    padding: 15px; /* Added padding */
    border: none;
    border-radius: 10px;
}

.shipping-details .form-row {
    display: flex;
    justify-content: space-between;
}

.shipping-details .form-row .form-group {
    width: calc(50% - 5px);
}

.shipping-details .error {
    color: red;
    font-size: 0.8em;
}

.thank-you {
    text-align: center;
    padding: 30px 0px;
}

.payment {
    position: relative;
    left: calc(25% - 0px);
}

#paypal-button-container{
    width: 50%;
}

.payment button {
    width: 50%;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 1.3rem;
}

.loading-animation {
    font-size: 18px;
}

.multi-step-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    position: relative;
    margin-top: 20px;
    transition: all 0.5s ease; /* Added transition for smooth step change */
}

.step {
    flex: 1;
    text-align: center;
    position: relative;
    padding: 10px 0;
    cursor: pointer;
}

.step:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    background: #ccc;
    transform: translateY(-50%);
    z-index: -1;
}

.step:first-child:before {
    width: 50%;
    left: 50%;
}

.step:last-child:before {
    width: 50%;
    left: 0;
}

.step-number {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background: #ccc;
    color: #fff;
    margin-right: 10px;
}

.step.active .step-number,
.step.completed .step-number {
    background: #007bff;
}

.step-label {
    display: inline-block;
    vertical-align: middle;
}

.step.completed .step-label,
.step.active .step-label {
    color: #007bff;
}

.accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease; /* Added transition for smooth accordion opening/closing */
    opacity: 0;
}

.accordion.open {
    max-height: 620px; /* Arbitrary large value to allow for full content expansion */
    opacity: 1;
    overflow: scroll;
}

.confirmation {
    text-align: center;
    margin-top: 20px;
}

.confirmation h2 {
    color: #007bff;
    font-size: 24px;
}

.confirmation p {
    font-size: 18px;
    margin-top: 10px;
}

.checkout-submit-btn{
    display: flex;
    justify-content: center;
}

.checkout-submit-btn button{
    width: 50%;
    border-radius: 10px;
    background-color: #b29ed0 !important;
}

.checkout-submit-btn button:hover{
background-color: #624889 !important;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .checkout-container {
        grid-template-columns: 1fr; /* Single column layout */
        gap: 20px;
    }

    .checkout-right {
        position: relative;
        top: 0;
        width: 100%;
    }

    .shipping-details .form-row {
        display: block;
    }

    .shipping-details .form-row .form-group {
        width: 100%;
    }
    .accordion .address_option{
        flex-direction: column;
    }

    .payment {
        position: relative;
        left: 0;
    }

    .payment button{
        width: 100%;
    }

    .checkout-left .payment-options .or-text{
        width: 100%;
        padding:20px
    }

    #paypal-button-container{
        width: 100%;
    }

    .step span{
        display: none;
    }

    .checkout-left .payment-options .or-text,
    .checkout-left .paypal-container .or-text{
        width: 100%; 
    }
}

.loading {
    text-align: center;
}

.accordion .address_option_head{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
}

.accordion .address_option{
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 10px;
}

.accordion .address_option_ans{
    cursor: pointer;
    background-color: #dee7ef;
    padding: 20px;
    border: none;
    border-radius: 10px;
    max-width: 400px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.8;
    display: flex;
    align-items: center;
}

.accordion .address_option_ans input{
    margin-right: 15px;
}