.profile-container {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-item {
    margin-bottom: 15px;
}

.profile-value {
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    /* background-color: #f4f4f4; */
    border: none;
}

.profile-value:focus{
  border-bottom: 4px solid !important;
}

.profile-row {
    display: flex;
    justify-content: space-between;
    gap: 10px; /* Adds space between items */
}

.profile-inline {
    flex: 1; /* Ensures that items take up equal space */
}

textarea.profile-value {
  border: none !important;
  outline: none;
  border-bottom: 1px solid !important;
  border-radius: 0px !important;
  height: 100px; /* Adjust as needed */
  resize: vertical; /* Allows vertical resizing */
}

.profile-update-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 10px !important;
    background-color: #007bff !important;
    color: white;
    cursor: pointer;
}

.profile-update-btn:hover {
    background-color: #b79be1 !important;
}

/* Container for the layout */
.order-container {
    display: flex;
  }
  
  /* Sidebar styles */
  .sidebar {
    width: 250px;
    height: 400px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 2px 0 5px 2px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }

  .sidebar ul a {
    color: #000;
  }
  
  .sidebar ul li {
    padding: 15px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 24px;
    margin-bottom: 10px;
  }

  .sidebar ul li:hover{
    color: #fff;
    background-color: rgb(209 191 232);
    border-radius: 10px;
  }
  
  .sidebar ul li.active {
    color: #fff;
    background-color: rgb(209 191 232);
    border-radius: 10px;
  }
  
  /* Main content styles */
  .main-content {
    width: 80%;
    max-height: 650px;
    padding: 20px;
    overflow: auto;
  }
  
  .orders_info {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 10px;
  }
  
  .order_card {
    width: 100%;
    background-color: #fff;
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px; 
  }
  
  .order_card img {
    width: 100px;
    height: 100px;
    margin-right: 20px; 
  }
  
  .order_card .left_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .order_card .right_section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: right;
    gap: 15px;
  }

  .order_card .right_section p{
    font-weight: 500;
    font-size: 20px;
  }

  .order_card .right_section .order_ivoice{
    display: flex;
    gap: 20px;
  }

  .invoice_buton{
    text-align: left;
  }

  .download_invoice_btn{
    background-color: #b28c8c;
    color: #fff;
    border: none;
    padding: 15px 20px;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
  }
  
  .order_card h2 {
    margin: 10px 0 0 0;
    font-size: 18px;
  }
  
  .order_card p {
    margin: 5px 0;
  }
  
  .order_status {
    font-weight: bold;
    color: green;
  }
  
  .order_ivoice .view_order_btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;
  }
  
  .order_ivoice .view_order_btn:hover {
    background-color: #0056b3;
  }

  .orders_info .product-order-info{
    display: flex;
  }

  .orders_info .product-order-info p{
    font-size: 16px;
    color: grey;
  }
  
@media (max-width: 768px) {
  .order-container {
    flex-direction: column;
  }

  .sidebar {
    display: none;
  }

  .main-content {
    width: 100%; 
    padding: 0px;
  }

  .order_card {
    flex-direction: column;
  }

  .order_card img {
    margin-bottom: 10px;
  }

  .order_card .left_section{
    padding: 10px;
  }

  .order_card .right_section {
    text-align: center;
    flex-direction: row-reverse;
  }

  .order_card .right_section .order_ivoice {
    text-align: center;
    flex-direction: row-reverse;
  }
}

/* Modal Content */
.modal-content {
  max-height: 90vh; /* Adjust as needed */
  overflow-y: auto;
}

.modal-header .close {
  position: absolute;
  top: 15px; /* Adjust if needed */
  right: 15px; /* Adjust if needed */
}

/* Order Details */
.order_detail {
  padding: 20px;
}

.order_detail .order_progress{
  display: flex;
}

.order_detail .order_info_date{
  display: flex;
  justify-content: space-between;
}

.order_info {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 15px;
  line-height: 0.5;
}

.order_cus_detail {
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
}

.order_cus_address {
  text-align: left;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Order Products */
.order_detail_products {
  margin-top: 20px;
}

.order_modal_head{
  position: relative;
  width: 100%;
  padding: 20px;
}

.product_heading {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.single_order_product {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 10px;
  width: 90% !important;
}

.single_order_product img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.single_order_product .single_order_product_info {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single_order_product .single_order_product_info span {
  display: block;
  font-weight: 600;
}

.single_order_product .single_order_product_quantity {
  color: grey;
  font-weight: 500;
}

/* Progress Bar */
#progressbar-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.step1,
.step2,
.step3,
.step4 {
  flex: 1;
  text-align: center;
  position: relative;
}

.step1 i,
.step2 i,
.step3 i,
.step4 i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.step1 p,
.step2 p,
.step3 p,
.step4 p {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.step1:before,
.step2:before,
.step3:before,
.step4:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #c5cae9;
  z-index: 1;
}

.step1.active:before,
.step2.active:before,
.step3.active:before,
.step4.active:before {
  background-color: #6520ff;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modal-content {
    max-height: 85vh;
  }

  .order_detail .card-body{
    padding: 0;
  }

  .order_detail .order_info_date{
    flex-direction: column-reverse;
    align-items: start;
  }

  .order_detail .order_progress{
    overflow: auto;
  }

  .order_detail,
  .order_info,
  .single_order_product {
    width: 100% !important;
  }

  .order_detail .order_info{
    line-height: 1.2;
  }

  .order_detail_products .single_order_product img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
}
